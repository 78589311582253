.c-cta {
    $c: &;

    @apply .relative .bg-cover .bg-center .bg-no-repeat .py-20;

    @screen md {
        @apply .py-32;
    }

    @screen lg {
        @apply .py-40;
    }

    @screen xl {
        @apply .py-52;
    }

    &__body {
        @apply .relative .z-20 .max-w-3xl .mx-auto .text-center;
    }

    &__title {
        @apply .text-white;
    }

    &:after {
        content: '';

        @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-primary .opacity-80;
    }
}