.c-button {
    $c: &;

    @apply .inline-flex .justify-center .text-0 .py-3.5 .px-12 .rounded-full .border .border-transparent .cursor-pointer .transition-all .duration-300 .ease-in-out;

    &__label {
        @apply .inline-block .font-semibold .text-xsm .leading-snug .tracking-custom .uppercase .transition-all .duration-300 .ease-in-out;
    }

    &--primary {
        @apply .bg-primary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-secondary;
        }
    }

    &--secondary {
        @apply .bg-secondary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-primary-dark;
        }

        &.is-inverted {
            @apply .bg-transparent .border-secondary;

            #{$c} {
                &__label {
                    @apply .text-secondary;
                }
            }

            &:hover,
            &:active {
                @apply .bg-secondary;

                #{$c} {
                    &__label {
                        @apply .text-white;
                    }
                }
            }
        }
    }

    &--tertiary {
        @apply .bg-tertiary;

        #{$c} {
            &__label {
                @apply .text-white;
            }
        }

        &:hover,
        &:active {
            @apply .bg-primary;
        }
    }
}