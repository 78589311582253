.c-service {
    $c: &;

    @apply .text-0 .w-full;

    &__figure {
        @apply .overflow-hidden;
    }

    &__image {
        @apply .transition-all .duration-300 .ease-in-out;
    }

    &__body {
        @apply .relative .pt-3;
        
        @screen md {
            &:before {
                content: '';
    
                width: 151px;
                height: 73px;
    
                background-image: url('/images/visual--small.svg');
    
                @apply .absolute .left-0 .bottom-0 .bg-no-repeat .bg-cover .bg-center .mb-2;
            }
        }
    }

    &__title {
        @screen md {
            @apply .pl-32;
        }
    }

    &.is-link {
        &:hover,
        &:active {
            #{$c} {
                &__image {
                    @apply .opacity-75;
                }
            }
        }
    }
}