.c-figure {
    $c: &;

    @apply .text-0;

    &__image {
        @apply .w-full;

        @screen md {
            @apply .w-auto;
        }
    }

    &--rounded {
        #{$c} {
            &__image {
                @apply .rounded;
            }
        }
    }

    &--grayscaled {
        #{$c} {
            &__image {
                filter: grayscale(100%);
            }
        }
    }
}