.c-header {
    $c: &;

    @apply .relative .text-0 .pt-12 .bg-tertiary-light;

    &__top,
    &__bottom {
        @apply .relative .z-20;
    }

    &__top {
    }

    &__bottom {
        @apply .flex .justify-center .py-16;

        @screen md {
            @apply .pb-24;
        }

        @screen lg {
            @apply .pb-32;
        }
    }

    &:after {
        content: '';

        background-image: url('/images/divider.svg');

        transform: translateY(-50%);

        top: 100%;

        @apply .absolute .right-0 .left-0 .w-full .h-8 .bg-no-repeat .bg-center .bg-cover .z-50;
    }

    &--alt {
        @apply .bg-cover .bg-center .bg-no-repeat;

        #{$c} {
            &__top {
            }

            &__bottom {
            }
        }

        &:before {
            content: '';

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .bg-primary .opacity-80;
        }
    }

    &--has-intro {
        #{$c} {
            &__bottom {
                @screen lg {
                    @apply .min-h-lg;
                }
            }
        }
    }
}