// -----------------------------------------------------------------------------
// elements/link
// -----------------------------------------------------------------------------

a {
    @apply .inline-block .no-underline .transition-all .duration-300 .ease-in-out;

    &:focus {
        @apply .outline-none;
    }
}
