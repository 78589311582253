// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5 {
    @apply .font-primary .text-primary .font-bold;
}

// 56/64
h1,
.h1 {
    @apply .text-3xl .leading-tighter;

    @screen lg {
        @apply .text-4.5xl;
    }

    @screen xl {
        @apply .text-5.5xl;
    }
}

// 44/52
h2,
.h2 {
    @apply .text-3xl .leading-tighter;

    @screen lg {
        @apply .text-4xl;
    }

    @screen xl {
        @apply .text-4.5xl;
    }
}

// 24/40
h3,
.h3 {
    @apply .text-2xl .leading-relaxed;
}

// 20
h4,
.h4 {
    @apply .text-xl .leading-relaxed;
}

// 18
h5,
.h5 {
    @apply .text-lg .leading-relaxed;
}
