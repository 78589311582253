// -----------------------------------------------------------------------------
// generics/fonts
// -----------------------------------------------------------------------------

// primary font
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Gotham-Thin';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Thin.woff2') format('woff2'),
    url('/fonts/Gotham-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-ThinItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-ThinItalic.woff2') format('woff2'),
    url('/fonts/Gotham-ThinItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-ExtraLight';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-ExtraLight.woff2') format('woff2'),
    url('/fonts/Gotham-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-ExtraLightItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-ExtraLightItalic.woff2') format('woff2'),
    url('/fonts/Gotham-ExtraLightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Light';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Light.woff2') format('woff2'),
    url('/fonts/Gotham-Light.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-LightItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-LightItalic.woff2') format('woff2'),
    url('/fonts/Gotham-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Book';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Book.woff2') format('woff2'),
    url('/fonts/Gotham-Book.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-BookItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-BookItalic.woff2') format('woff2'),
    url('/fonts/Gotham-BookItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Medium';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Medium.woff2') format('woff2'),
    url('/fonts/Gotham-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-MediumItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-MediumItalic.woff2') format('woff2'),
    url('/fonts/Gotham-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Bold';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Bold.woff2') format('woff2'),
    url('/fonts/Gotham-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-BoldItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-BoldItalic.woff2') format('woff2'),
    url('/fonts/Gotham-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Black';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Black.woff2') format('woff2'),
    url('/fonts/Gotham-Black.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Ultra';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-Ultra.woff2') format('woff2'),
    url('/fonts/Gotham-Ultra.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-UltraItalic';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/Gotham-UltraItalic.woff2') format('woff2'),
    url('/fonts/Gotham-UltraItalic.woff') format('woff');
}

// secondary font
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'DM Serif Display';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/DMSerifDisplay-Regular.woff2') format('woff2'),
    url('/fonts/DMSerifDisplay-Regular.woff') format('woff');
}

@font-face {
    font-family: 'DM Serif Display';
    font-weight: normal;
    font-style: italic;

    src: url('/fonts/DMSerifDisplay-Italic.woff2') format('woff2'),
    url('/fonts/DMSerifDisplay-Italic.woff') format('woff');
}