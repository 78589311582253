.c-nav {
    $c: &;

    @apply .text-0;

    &__list {
    }

    &__item {
    }

    &__link {
    }

    &__label {
        @apply .relative .inline-block .transition-all .duration-300 .ease-in-out;
    }

    &--primary {
        #{$c} {
            &__list {
                @apply .flex .flex-wrap .-ml-6 .-mb-6;

                @screen xl {
                    @apply .-ml-9;
                }
            }
        
            &__item {
                @apply .w-full .pl-6 .pb-6;

                @screen lg {
                    @apply .w-auto;
                }

                @screen xl {
                    @apply .pl-9;
                }

                &.is-active {
                    #{$c} {
                        &__link {
                            &:hover,
                            &:active {
                                #{$c} {
                                    &__label {
                                        @apply .text-primary;
                                    }
                                }
                            }
                        }

                        &__label {
                            @apply .font-bold .border-secondary;
                        }
                    }
                }
            }
        
            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }
        
            &__label {
                @apply .text-white .text-lg .leading-snug .pb-2 .border-b-2 border-transparent;

                @screen lg {
                    @apply .text-base .text-primary;
                }

                @screen xl {
                    @apply .text-lg;
                }
            }
        }

        &#{$c}--alt {
            #{$c} {
                &__label {
                    @apply .text-white;
                }
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .flex .-ml-6;
            }

            &__item {
                @apply .pl-6;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__icon {
                            .c-icon {
                                @apply .text-secondary;
                            }
                        }
                    }
                }
            }

            &__icon {
                @apply .text-white;
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .-ml-12 .-mb-6;

                @screen lg {
                    @apply .flex .flex-wrap;
                }
            }

            &__item {
                @apply .pl-12 .pb-6;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-base .leading-none .text-white;
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
                @apply .flex .-ml-6;
            }

            &__item {
                @apply .pl-6;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xs .leading-none .text-white;
            }
        }
    }
}