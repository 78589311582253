.c-icon {
    @apply .fill-current .transition-all .duration-300 .ease-in-out;

    &--facebook {
        width: 8px;
        height: 16px;
    }

    &--linkedin {
        width: 16px;
        height: 16px;
    }

    &--instagram {
        width: 16px;
        height: 16px;
    }

    &--long-arrow {
        width: 20px;
        height: 13px;
    }

    &--long-arrow-down {
        width: 15px;
        height: 22px;
    }
}