.c-container {
    $c: &;

    // 1200px
    @apply .max-w-7xl .mx-auto .px-6;

    @screen md {
        @apply .px-8;
    }

    @screen lg {
        @apply .px-12;
    }

    @screen xl {
        @apply .px-16;
    }

    &--full {
        @apply .w-full .max-w-full;
    }

    // 1400px
    &--xl {
        @apply .max-w-8xl;
    }
}