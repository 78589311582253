// -----------------------------------------------------------------------------
// elements/button
// -----------------------------------------------------------------------------

button {
    @apply .transition-all .duration-300 .ease-in-out;
    
    &:focus {
        @apply .outline-none;
    }
}
