.c-mobile-nav-handle {
    $c: &;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        @apply .bg-primary;
    }

    &--alt {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            @apply .bg-white;
        }
    }
}