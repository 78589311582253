.c-hero {
    $c: &;

    @apply .text-0 .text-center .max-w-3xl .mx-auto;

    &__heading {
    }

    &__title {
    }

    &__body {
        @apply .mt-14;
    }

    &__text {
    }

    &--xl {
        #{$c} {
            &__body {
                @apply .mt-20;
            }
        }
    }

    &--alt {
        #{$c} {
            &__title,
            &__text {
                @apply .text-white;
            }
        }
    }
}