.c-text {
    $c: &;

    @apply .text-base;

    b,
    strong {
        @apply .font-semibold .text-tertiary;
    }

    a {
        @apply .border-b;

        &:hover,
        &:active {
            @apply .border-transparent;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply .text-2xl .leading-relaxed .mb-9;

        &:last-child {
            @apply .mb-0;
        }
    }

    p,
    ul {
        @apply .mb-8;

        @screen md {
            @apply .mb-8;
        }

        &:last-child {
            @apply .mb-0;
        }
    }

    ul {
        li {
            @apply .relative .pl-2.5;

            &:after {
                content: '•';

                @apply .absolute .left-0 .top-0 .font-primary .font-normal .text-base;
            }
        }
    }

    &--narrow {
        p,
        ul {
            @apply .mb-8;

            &:last-child {
                @apply .mb-0;
            }
        }
    }
}