.c-block {
    $c: &;

    @apply .relative .flex .flex-wrap;

    &__body {
        @apply .relative .z-30 .flex;
    }

    &__text {
        @apply .mt-9;
    }

    &__link {
        @apply .mt-12;
    }

    &:after {
        content: '';

        background-image: url('/images/visual.svg');

        width: 530px;
        height: 256px;

        @apply .absolute .bg-no-repeat .bg-cover .bg-center .z-20;
    }

    &.is-vertical-aligned {
        #{$c} {
            &__body {
                @screen md {
                    @apply .h-full .items-center .py-20;
                }
            }
        }
    }

    &--1 {
        #{$c} {
            &__body {
                @screen md {
                    @apply .pl-12;
                }
    
                @screen lg {
                    @apply .pl-8;
                }
    
                @screen xl {
                    @apply .pl-20;
                }
            }
        }

        &:after {
            top: 62px;
            left: 500px;
        }

        &.is-vertical-aligned {
            &:after {
                top: 173px;
            }
        }
    }

    &--2 {
        #{$c} {
            &__body {
                @screen md {
                    @apply .pr-12;
                }
    
                @screen lg {
                    @apply .pr-16;
                }
    
                @screen xl {
                    @apply .pl-16 .pr-40;
                }
            }
        }

        &:after {
            bottom: 0;
            right: -244px;
        }
    }

    &--3 {
        #{$c} {
            &__body {
                @screen md {
                    @apply .pr-12;
                }
    
                @screen lg {
                    @apply .pr-16;
                }
    
                @screen xl {
                    @apply .pl-16;
                }
            }
        }
        
        &:after {
            top: 94px;
            left: 155px;
        }

        &.is-vertical-aligned {
            #{$c} {
                &__body {
                    @apply .pt-0;
                }
            }
        }
    }
}