.c-cookie-consent {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateY(100%);

    $c: &;

    @apply .bg-grey .text-center .p-4 .transform .translate-y-full .transition-all .duration-500 .ease-in-out;

    &__content {
        @screen md {
            @apply .inline-flex .items-center;
        }
    }

    &__message {
        @apply .text-sm .leading-normal .mb-4 .text-white;

        @screen md {
            @apply .mr-4 .mb-0;
        }

        a {
            @apply .relative .text-white .font-bold .text-sm;
    
            &:after {
                content: '';
    
                top: 100%;
    
                @apply .absolute .left-0 .right-auto .w-full .h-px .bg-white .-mt-1 .transition-all .duration-300 .ease-in-out;
            }
    
            &:hover,
            &:active {
                &:after {
                    @apply .left-auto .right-0 .w-0;
                }
            }
        }
    }

    &.in-view {
        @apply .translate-y-0;
    }
}
