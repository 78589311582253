.c-content {
    $c: &;

    &__section {
        @apply .relative .overflow-hidden;

        &--home-about {
            @apply .z-20;

            @screen xl {
                height: 958px;
            }
        }

        &--home-services {
            @apply .z-10;

            @screen xl {
                margin-top: -190px;
            }

            &:after {
                content: '';

                z-index: -1;

                @apply .w-full .absolute .bg-tertiary-light .h-full .top-0 .bottom-0 .left-0;

                @screen md {
                    border-top-right-radius: 100px;
                    border-bottom-right-radius: 100px;
    
                    @apply .w-2/3;
                }
            }
        }
    }
}