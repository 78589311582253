// -----------------------------------------------------------------------------
// elements/input
// -----------------------------------------------------------------------------

input,
textarea {
    @apply .rounded-none .p-0 .appearance-none;

    &:focus {
        @apply .outline-none;
    }

    &::placeholder {
        @apply .opacity-0;
    }
}

textarea {
    @apply .resize-none;
}

select {
    @apply .appearance-none .rounded-none;

    &:focus {
        @apply .outline-none;
    }
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    @apply .appearance-none;
}
