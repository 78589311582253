.c-link {
    $c: &;

    @apply .text-0 .inline-flex .items-center;

    &__label {
        @apply .inline-block .text-base .leading-relaxer .font-primary .border-b .border-transparent .transition-all .duration-300 .ease-in-out;
    }

    &__icon {
        @apply .inline-block .transition-all .duration-300 .ease-in-out;
    }

    &:hover,
    &:active {
        #{$c} {
            &__icon {
                transform: translateX(-4px);
            }
        }
    }

    &--primary {
        #{$c} {
            &__label {
            }

            &__icon {
            }
        }
    }

    &--secondary {
        #{$c} {
            &__label,
            &__icon {
                @apply .text-secondary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .border-secondary;
                }
            }
        }
    }

    &--tertiary {
        @apply .flex-col;

        #{$c} {
            &__label,
            &__icon {
                @apply .text-tertiary;
            }

            &__icon {
                animation: arrow-bounce 1.4s infinite;

                @apply .mt-2;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-primary;
                }

                &__icon {
                    transform: translateX(0);

                    .c-icon {
                        @apply .text-primary;
                    }
                }
            }
        }
    }

    &.has-icon-before {
        #{$c} {
            &__icon {
                @apply .mr-4;
            }
        }
    }

    &.has-icon-after {
        #{$c} {
            &__icon {
                @apply .ml-4;
            }
        }
    }
}

@keyframes arrow-bounce {
    0% {
    }
    50% {
        transform: translateY(12px);
    }
    100% {
        transform: translateY(0);
    }
}
