.c-form {
    $c: &;

    @apply .text-0;

    &__rows {
        @apply .-mb-4;
    }

    &__row {
        @apply .pb-4;
    }

    &__columns {
        @apply .-mb-4 .-ml-4;

        @screen md {
            @apply .flex;
        }
    }

    &__column {
        @apply .flex-1 .pb-4 .pl-4;
    }
}