.member {
    margin-bottom: 30px;
    
    &-image {
        width: 200px;
        height: 200px;
        margin: 0 auto 20px;

        background-repeat: no-repeat;
        background-size: cover;
    }
}