.c-form-field {
    $c: &;

    @apply .relative .text-0;

    &__wrapper {
        @apply .relative;
    }

    &__input,
    &__label {
        @apply .text-base .leading-relaxer .py-2.5 .px-6 .transition-all .duration-300 .ease-in-out;
    }

    &__input {
        @apply .text-primary .w-full .bg-transparent .pr-40 .border .border-primary-light .rounded;

        &:focus {
            @apply .border-tertiary;
        }

        &:not([value='']):not(:focus),
        &:focus {
            + #{$c} {
                &__label:nth-child(n) {
                    @apply .left-auto .right-0 .text-tertiary;
                }
            }
        }
    }

    &__label {
        @apply .absolute .left-0 .text-primary-light;
    }

    &__errors {
        @apply .text-sm .text-error .italic .mt-2;
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;
            }
        }
    }

    &--alt {
        #{$c} {
            &__input,
            &__label {
                @apply .text-primary-lighter;
            }
        }
    }
}