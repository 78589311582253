.c-file-upload {
    $c: &;

    @screen md {
        @apply .flex;
    }

    &__input {
        @apply .w-0 .h-0 .opacity-0 .text-0 .leading-none .invisible .m-0 .p-0;
    }

    &__value {
        @screen md {
            @apply .flex-grow;
        }
    }

    &__label {
        @apply .w-full .mt-4;

        @screen md {
            @apply .flex-shrink-0 .ml-4 .w-auto .mt-0;
        }
    }
}