/* purgecss start ignore */
@tailwind base;

@tailwind components;

@import "01-generics/_base.scss";
@import "01-generics/_fonts.scss";
@import "02-vendors/_aos.scss";
@import "02-vendors/_hamburgers.scss";
@import "02-vendors/_photoswipe.scss";
@import "03-elements/_body.scss";
@import "03-elements/_button.scss";
@import "03-elements/_heading.scss";
@import "03-elements/_input.scss";
@import "03-elements/_link.scss";
@import "04-components/_block.scss";
@import "04-components/_button.scss";
@import "04-components/_container.scss";
@import "04-components/_content.scss";
@import "04-components/_cookie-consent.scss";
@import "04-components/_cta.scss";
@import "04-components/_figure.scss";
@import "04-components/_file-upload.scss";
@import "04-components/_footer.scss";
@import "04-components/_form-field.scss";
@import "04-components/_form.scss";
@import "04-components/_header.scss";
@import "04-components/_hero.scss";
@import "04-components/_icon.scss";
@import "04-components/_link.scss";
@import "04-components/_mobile-nav-handle.scss";
@import "04-components/_mobile-nav.scss";
@import "04-components/_nav.scss";
@import "04-components/_page.scss";
@import "04-components/_service.scss";
@import "04-components/_teammembers.scss";
@import "04-components/_text.scss";
/* purgecss end ignore */

@tailwind utilities;