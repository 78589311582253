.c-footer {
    $c: &;

    @apply .relative .text-0 .text-white;

    &__top {
        @apply .bg-primary .py-16 .text-base;

        @screen md {
            @apply .py-20;
        }

        @screen lg {
            @apply .pt-30 .pb-20;
        }
    }

    &__bottom {
        @apply .bg-primary-dark .text-xs .leading-none .py-5.5;
    }

    &:after {
        content: '';

        background-image: url('/images/divider.svg');

        transform: translateY(-50%);

        @apply .absolute .top-0 .right-0 .left-0 .w-full .h-8 .bg-no-repeat .bg-center .bg-cover;
    }
}